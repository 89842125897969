







































































import { Component, Vue } from 'vue-property-decorator'
import { Equipment } from '@/models'
import ModalRegisterEquipment from '@/components/Modal/ModalRegisterEquipment.vue'
import GroupService from '@/services/GroupService'
import PreviewText from '@/helpers/PreviewText'

/**
 * f2-106
 * グループ設備設定画面
 */
@Component({ components: { ModalRegisterEquipment } })
export default class EquipmentList extends Vue {
  private equipments: Equipment[] = []
  private selectedEquipment: Equipment = new Equipment()
  private modalMess: string = ''
  private deleteEqipmentId!: number

  created() {
    this.getEquipments()
  }

  /**
   * Call API get all equipments
   */
  getEquipments() {
    GroupService.getEquipmentsByGroupId(this.$route.params.groupId)
      .then(res => {
        if (res.status === 200)
          this.equipments = res.data.map((item: any) => new Equipment(item))
      })
      .catch(err => {
        this.modalMess = this.$t('common.message.uncatch_error') as string
        this.$bvModal.show('modal-error')
      })
  }

  /**
   * Show modal to edit equipment
   */
  editEquipment(equip: Equipment) {
    this.selectedEquipment = equip
    this.$bvModal.show('register-equipment')
  }

  /**
   * Show modal to create equipment
   */
  addEquipment() {
    this.selectedEquipment = new Equipment()
    this.$bvModal.show('register-equipment')
  }

  openDeleteConfirm(eqipmentId: number) {
    this.deleteEqipmentId = eqipmentId
    this.$bvModal.show('modal-confirm')
  }

  /**
   * Call API to delete equipment
   */
  deleteEquipment() {
    this.$blockui.show()
    GroupService.deleteEquipment({
      group_id: Number(this.$route.params.groupId),
      equipment_id: this.deleteEqipmentId
    })
      .then(res => {
        if (res.status === 200) {
          this.modalMess = this.$t('common.message.deleted') as string
          this.$bvModal.show('modal-success')
          this.getEquipments()
        }
      })
      .catch(() => {
        this.modalMess = this.$t('common.message.delete_fail') as string
        this.$bvModal.show('modal-error')
      })
      .finally(() => this.$blockui.hide())
  }

  /**
   * Call API to create equipment
   */
  registerEquipment(newVal: Equipment) {
    if (newVal.id) {
      this.$blockui.show()
      GroupService.editEquipment({
        group_id: Number(this.$route.params.groupId),
        equipment_id: newVal.id,
        name: newVal.name,
        memo: newVal.memo
      })
        .then(res => {
          if (res.status === 200) {
            this.modalMess = this.$t('common.message.registered') as string
            this.$bvModal.show('modal-success')
            this.getEquipments()
          }
        })
        .catch(() => {
          this.modalMess = this.$t('common.message.register_fail') as string
          this.$bvModal.show('modal-error')
        })
        .finally(() => this.$blockui.hide())
      return
    }
    this.$blockui.show()
    GroupService.registerEquipment({
      group_id: Number(this.$route.params.groupId),
      name: newVal.name,
      memo: newVal.memo
    })
      .then(res => {
        if (res.status === 201) {
          this.modalMess = this.$t('common.message.registered') as string
          this.$bvModal.show('modal-success')
          this.getEquipments()
        }
      })
      .catch(() => {
        this.modalMess = this.$t('common.message.register_fail') as string
        this.$bvModal.show('modal-error')
      })
      .finally(() => this.$blockui.hide())
  }
}
