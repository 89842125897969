var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"register-equipment","body-class":"px-5 pb-5","centered":"","hide-footer":""},on:{"hide":_vm.handlePreventEvent}},[_c('ValidationObserver',{ref:"registerEquipment",staticClass:"form d-flex flex-column px-3",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"name py-2"},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('modal_register_equipment.name'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t('common.form.require'))+" ")])]),_c('div',{staticClass:"form-input"},[_c('ValidationProvider',{attrs:{"rules":{ required: true, max: 255, no_script_tag: true },"name":"equipment_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cloneEquipment.name),expression:"cloneEquipment.name"}],staticClass:"form-control bg-light position-relative z-1",class:{ 'border-danger': errors[0] },attrs:{"type":"text","name":"equipment_name"},domProps:{"value":(_vm.cloneEquipment.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.cloneEquipment, "name", $event.target.value)}}}),(errors[0])?_c('span',{staticClass:"text-danger slide-down-40-percent d-block z-n1"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"memo py-2"},[_c('label',{staticClass:"mb-1",attrs:{"for":"equipmentMemo"}},[_vm._v(" "+_vm._s(_vm.$t('modal_register_equipment.memo'))+" ")]),_c('div',{staticClass:"form-input"},[_c('ValidationProvider',{attrs:{"rules":{ max: 20000, no_script_tag: true },"name":"equipment_memo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.cloneEquipment.memo),expression:"cloneEquipment.memo"}],staticClass:"form-control bg-light position-relative z-1",class:{ 'border-danger': touched && errors[0] },attrs:{"name":"equipment_memo","rows":"5"},domProps:{"value":(_vm.cloneEquipment.memo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.cloneEquipment, "memo", $event.target.value)}}}),_vm._v(" "),(touched && errors[0])?_c('span',{staticClass:"text-danger slide-down-40-percent d-block z-n1"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"action-area flex-center py-3 mt-5"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('button',{staticClass:"btn btn-outline-secondary-deep w--34 me-5",on:{"click":_vm.confirmHideModal}},[_vm._v(" "+_vm._s(_vm.$t('common.btn.btn_cancel'))+" ")]),_c('button',{staticClass:"btn btn-primary text-white w--34",on:{"click":function($event){return handleSubmit(_vm.onSubmitRegisterEq)}}},[_vm._v(" "+_vm._s(_vm.$t('common.btn.btn_register'))+" ")])])])]}}])}),_c('ModalConfirm',{attrs:{"id":"modal-confirm-equipment-register","confirmMess":_vm.$t('common.message.unsave_edit')},on:{"yes":_vm.hideModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }