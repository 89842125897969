





























































































import { Prop, Component, Vue, Watch } from 'vue-property-decorator'
import { Equipment } from '@/models'
//@ts-ignore
import _ from 'lodash'

/**
 * f2-106a
 * グループ設備登録・編集ポップアップ等
 */
@Component
export default class ModalRegisterEquipment extends Vue {
  @Prop({ default: new Equipment() }) private equipment!: Equipment
  private cloneEquipment: Equipment = new Equipment()
  private ableClose: boolean = false

  @Watch('equipment')
  hanldeCloneEquipment() {
    this.cloneEquipment = new Equipment(this.equipment)
  }

  handlePreventEvent(event: any) {
    if (!this.ableClose && !_.isEqual(this.cloneEquipment, this.equipment)) {
      event.preventDefault()
      this.confirmHideModal()
    }
  }

  confirmHideModal() {
    if (_.isEqual(this.cloneEquipment, this.equipment)) {
      this.hideModal()
    } else {
      this.$bvModal.show('modal-confirm-equipment-register')
    }
  }

  async hideModal() {
    await this.handleHide()
    this.ableClose = false
  }

  async handleHide() {
    this.ableClose = true
    this.hanldeCloneEquipment()
    this.$bvModal.hide('register-equipment')
  }

  onSubmitRegisterEq() {
    this.$emit('submit', this.cloneEquipment)
    this.hideModal()
  }
}
